// extracted by mini-css-extract-plugin
export var back = "Obrigado-module--back--7JcN8";
export var buttonWrapper = "Obrigado-module--button-wrapper--Ew6LP";
export var gatsbyFocusWrapper = "Obrigado-module--gatsby-focus-wrapper---u6xW";
export var hideOnDesktop = "Obrigado-module--hide-on-desktop--TVbEL";
export var hideOnMobile = "Obrigado-module--hide-on-mobile--ZeV5H";
export var iconCar = "Obrigado-module--icon-car--pe6U-";
export var iconCars = "Obrigado-module--icon-cars--j-9o0";
export var iconClock = "Obrigado-module--icon-clock--DmhdW";
export var iconEletricCar = "Obrigado-module--icon-eletric-car--CP+gu";
export var iconFacebook = "Obrigado-module--icon-facebook--AyHtP";
export var iconGear = "Obrigado-module--icon-gear--LAV2u";
export var iconInstagram = "Obrigado-module--icon-instagram--z+Wmw";
export var iconKm = "Obrigado-module--icon-km--OJt3f";
export var iconPhone = "Obrigado-module--icon-phone--ceVlZ";
export var iconRight = "Obrigado-module--icon-right--bVr38";
export var iconWhatsapp = "Obrigado-module--icon-whatsapp--q4-pc";
export var imageWrapper = "Obrigado-module--image-wrapper--I5wTP";
export var pageWidth = "Obrigado-module--page-width--IwrGX";
export var path1 = "Obrigado-module--path1--5AlmC";
export var path2 = "Obrigado-module--path2--NqfTJ";
export var path3 = "Obrigado-module--path3--RWh0E";
export var thankYou = "Obrigado-module--thank-you---yzDv";
export var thankYouWrapper = "Obrigado-module--thank-you-wrapper--5STRR";
export var view = "Obrigado-module--view--5xuS+";
export var views = "Obrigado-module--views--45n0U";