import React from 'react'

import * as st from '~/assets/styl/Obrigado.module.styl'

import thankYou from '~/assets/img/thankyou.jpg'

import { Link } from 'gatsby'
import { LazyImage } from 'react-lazy-images'

const Obrigado = () => (
  <>
    <div className={st.thankYouWrapper}>
      <section className={st.thankYou}>
        <h2>Mensagem enviada com sucesso!</h2>
        <p>
          Sua mensagem foi enviada para a nossa central de atendimento. Em breve
          entraremos em contato.
        </p>
        <Link to="/" className={st.back}>
          Voltar
        </Link>
      </section>
      <div className={st.imageWrapper}>
        <LazyImage
          src={thankYou}
          placeholder={({ ref }) => (
            <img
              ref={ref}
              src={thankYou}
              alt="Mulher sorrindo em frente ao notebook"
            />
          )}
          actual={({ imageProps }) => <img {...imageProps} />}
        />
      </div>
    </div>
  </>
)

export default Obrigado
